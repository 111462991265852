<template>
	<ValidationForm
		#default="{ handleSubmit }"
		tag="div"
	>
		<ProductRolesLayout	:product-id="productIdToView">
			<template #content>
				<ProductRolesRoleForm
					:role.sync="role"
					:product-id="productIdToView"
				>
					<template #title>
						Create New User Role
					</template>
				</ProductRolesRoleForm>
			</template>
			<template #footer>
				<div class="d-flex">
					<SecondaryActionButton
						class="mr-4"
						@click="navigateToOverview"
					>
						Back to Overview
					</SecondaryActionButton>
					<v-spacer />
					<PrimaryActionButton @click="handleSubmit(createRole)">
						Create
					</PrimaryActionButton>
				</div>
			</template>
		</ProductRolesLayout>
	</ValidationForm>
</template>
<script>

import { CS_PORTAL_PRODUCT_ROLES, ADMIN_PORTAL_PRODUCT_ROLES } from '../../router/route-names.js'
import ProductRolesRoleForm from './product-roles-role-form.vue'
import SecondaryActionButton from '../secondary-action-button.vue'
import PrimaryActionButton from '../primary-action-button.vue'
import ProductRolesLayout from './product-roles-layout.vue'
import { upsertProductRole } from '../../utils/api/product-roles.js'
import { showSnackbar } from '../../state/snackbar.js'
import ValidationForm from '../validation-form.vue'

export default {
	components: { ValidationForm, ProductRolesLayout, PrimaryActionButton, SecondaryActionButton, ProductRolesRoleForm },
	props: {
		productIdToView: Number,
		loggedInProductId: Number
	},
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			role: {
				name: '',
				permissions: []
			}
		}
	},
	methods: {
		navigateToOverview () {
			this.$router.push({
				name: this.productIdToView === 1 ? ADMIN_PORTAL_PRODUCT_ROLES : CS_PORTAL_PRODUCT_ROLES
			})
		},
		async createRole () {
			await upsertProductRole(this.productIdToView, this.role)
			this.showSnackbar('This user role has been created')
			this.navigateToOverview()
		}
	}
}
</script>
